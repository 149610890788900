import React from "react"
import { Typography } from "@material-ui/core"

import { useStyles } from "./styles"

const title = "We modernize and align business processes, technology, and data"
const paragraph1 = "To keep up with the complex regulatory environment and increased competitive landscape, financial institutions need integrated and optimized processes, data, and technology. Anything less will increase risk, reduce operational efficiency, and potentially decrease client retention. We apply decades of industry experience to deliver integrated business technologies, trusted enterprise data, and actionable insights for executives, managers, and analysts across the investment lifecycle."
const paragraph2 = ""
const introduction = [
  paragraph1,
  paragraph2
]

const ServicesIntroduction = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        {introduction.map((item, index) => (
          <Typography
            key={`services-introduction-${index}`}
            variant="body2"
            className={classes.description}
          >
            {item}
          </Typography>
        ))}
      </div>
    </div>
  )
}

export default ServicesIntroduction
