import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Hero from "../../components/Hero"
import ServicesIntroduction from "../../sections/services/ServicesIntroduction"
import Implementations from "../../sections/home/Implementations"

import servicesHeroBgUrl from "../../../media/services-banner-overlay.png"

const servicesHeroTitle = "Our Services"
const servicesHeroDescription = ""

const ServicesPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title="Services" />
    <Hero
      backgroundImage={servicesHeroBgUrl}
      title={servicesHeroTitle}
      description={servicesHeroDescription}
    />
    <ServicesIntroduction />
    <Implementations />
  </Layout>
)

export default ServicesPage
